import MEETING_STRUCT from '../meeting';
import { getById } from '../../../../utils';
import CLIENT_STRUCT from '../client';
import EVENTS_STRUCT from '../event';
import find from 'lodash.find';

const INVEST_STRUCT = {
  CUSTOMER_ID: 'customer_id',
  EVENT_ID: 'event_id',
  FINE: 'fine',
  ID: 'id',
  REPRESENTATIVE_ID: 'representative_id',
};

export const investFilterFn = meeting => invest =>
  invest[INVEST_STRUCT.REPRESENTATIVE_ID] ===
    meeting[MEETING_STRUCT.REPRESENTATIVE_ID] &&
  invest[INVEST_STRUCT.CUSTOMER_ID] === meeting[MEETING_STRUCT.CUSTOMER_ID];

const mapInvests = propName => (meeting, invests) =>
  invests.filter(investFilterFn(meeting)).map(invest => invest[propName]);

export const getClientEventsIds = mapInvests(INVEST_STRUCT.EVENT_ID);
export const getClientInvestIds = mapInvests(INVEST_STRUCT.ID);
export const getCorrespondingInvestId = (eventId, clientId, invests) => {
  const invest = find(
    invests,
    invest =>
      invest[INVEST_STRUCT.CUSTOMER_ID] === clientId &&
      invest[INVEST_STRUCT.EVENT_ID] === eventId,
  );

  return invest[INVEST_STRUCT.ID];
};

export const getResultCoef = (eventsIds, events, invests, client) => {
  return eventsIds.reduce((acc, eventId) => {
    const invest = getById(
      invests,
      getCorrespondingInvestId(eventId, client[CLIENT_STRUCT.ID], invests),
    );

    const investIsApplicable = !invest[INVEST_STRUCT.FINE];

    return investIsApplicable
      ? acc * getById(events, eventId)[EVENTS_STRUCT.EFFECT]
      : acc;
  }, 1);
};

export default INVEST_STRUCT;
