import React from 'react';
import { branch } from 'baobab-react/higher-order';

import {
  meSelector,
  currentGameSelector,
} from '../../../store/struct/selectors';

import GAME_STRUCT from '../../../store/struct/entities/game';
import CLIENT_STRUCT from '../../../store/struct/entities/client';
import { SUBTYPES } from '../../../components/form/input';

import Form, { PARAMS, TYPES as FORM_TYPES } from '../../../components/form';

import guide from './guide.pdf';
import styles from './index.module.scss';

const Rules = props => {
  const { currentGame } = props;
  const currentUser = props.currentUser && props.currentUser[0];

  if (!currentGame || !currentUser) {
    return '';
  }

  return (
    <div className={`${styles.container} ${styles.containerBottomPadding}`}>
      <Form
        config={[
          {
            [PARAMS.ID]: 'game_name',
            [PARAMS.TITLE]: 'Название игры:',
            [PARAMS.INITIAL_VALUE]: currentGame[GAME_STRUCT.NAME],
            [PARAMS.TYPE]: FORM_TYPES.INPUT,
            [PARAMS.READONLY]: true,
          },
          {
            [PARAMS.ID]: 'game_desc',
            [PARAMS.TITLE]: 'Цели и описание игры:',
            [PARAMS.INITIAL_VALUE]: currentGame[GAME_STRUCT.DESC],
            [PARAMS.TYPE]: FORM_TYPES.INPUT,
            [PARAMS.SUBTYPE]: SUBTYPES.TEXTAREA,
            [PARAMS.READONLY]: true,
          },
          {
            [PARAMS.ID]: 'game_capabilities_and_restrictions',
            [PARAMS.TITLE]: 'Возможности и ограничения Клиента:',
            [PARAMS.INITIAL_VALUE]: currentUser[CLIENT_STRUCT.RULES],
            [PARAMS.TYPE]: FORM_TYPES.INPUT,
            [PARAMS.SUBTYPE]: SUBTYPES.TEXTAREA,
            [PARAMS.READONLY]: true,
          },
          {
            [PARAMS.ID]: 'game_refs',
            [PARAMS.TITLE]: 'Инструкция:',
            [PARAMS.INITIAL_VALUE]: [guide, 'Ссылка'],
            [PARAMS.TYPE]: FORM_TYPES.LINK,
            [PARAMS.READONLY]: true,
            [PARAMS.ATTRS]: {
              target: '_blank',
            },
          },
        ]}
      />
    </div>
  );
};

export default branch(
  {
    currentUser: meSelector(),
    currentGame: currentGameSelector(),
  },
  Rules,
);
