import React, {
  useCallback,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { ICONS } from '../../components/Icons';

import styles from './index.module.scss';

const LABEL = 'Тип файла: .xlsx';

const MyDropzone = (props, ref) => {
  const [file, setFile] = useState(null);
  const onDrop = useCallback(acceptedFiles => {
    setFile(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  useImperativeHandle(ref, () => ({
    getFile: () => file,
  }));

  return (
    <React.Fragment>
      <div
        {...getRootProps({
          className: styles.dropzone,
        })}
      >
        <input {...getInputProps({ accept: '.toml' })} />
        {file ? (
          <p className={styles.fileInfo}>
            <span className={styles.name}>{file.name}</span>
            <span className={styles.size}>
              {(file.size / 1024).toFixed(1)} KB
            </span>
          </p>
        ) : (
          <p className={styles.select}>
            <ICONS.Upload />
            <span>Выбрать файл</span>
          </p>
        )}
      </div>
      <span className={styles.label}> {LABEL} </span>
    </React.Fragment>
  );
};

export default forwardRef(MyDropzone);
