import React, { useEffect, useMemo, useState } from 'react';
import { branch } from 'baobab-react/higher-order';
import PropTypes from 'prop-types';

import { getTableConfig, getTableData } from './forms';
import { calcWithCoef, FIELD_STRUCT, HEADERS } from './forms/const';
import Table from '../../../../components/table';

import {
  clientsSelector,
  eventsSelector,
  investsSelector,
  reportsSelector,
} from '../../../../store/struct/selectors';
import { getResultCoef } from '../../../../store/struct/entities/invest';

const Results = ({
  onChange,
  events,
  eventsIds,
  client,
  initialState,
  isResultDemonstration,
  invests,
  reports,
  clients,
  selectedReport,
  agentId,
}) => {
  const [state, setState] = useState(initialState);
  const [calcState, setCalcState] = useState({});
  const resultCoef = getResultCoef(eventsIds, events, invests, client);

  const tableData = useMemo(() => {
    return getTableData(client);
  }, [client]);

  const tableConfig = useMemo(() => {
    const onTableChange = (fieldData, val) => {
      const newState = {
        ...state,
        [fieldData[FIELD_STRUCT.CONTRIBUTE_TO]]: val,
      };
      setState(newState);
      setCalcState({
        ...calcState,
        [fieldData[FIELD_STRUCT.CONTRIBUTE_TO]]: calcWithCoef({
          fieldData,
          client,
          resultCoef,
          state: newState,
        }),
      });
    };

    return getTableConfig({
      client,
      resultCoef,
      state,
      onChange: onTableChange,
      isResultDemonstration,
      reports,
      clients,
      selectedReport,
      agentId,
    });
  }, [
    client,
    resultCoef,
    state,
    isResultDemonstration,
    calcState,
    clients,
    reports,
    selectedReport,
    agentId,
  ]);

  useEffect(() => {
    onChange(calcState);
  }, [calcState, onChange]);

  return (
    <Table
      keyName={'id'}
      headers={isResultDemonstration ? HEADERS.slice(0, -1) : HEADERS}
      data={tableData}
      config={tableConfig}
    />
  );
};

Results.propTypes = {
  client: PropTypes.object.isRequired,
  events: PropTypes.array.isRequired,
  eventsIds: PropTypes.array.isRequired,
  initialState: PropTypes.object,
  invests: PropTypes.array.isRequired,
  isResultDemonstration: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  reports: PropTypes.array.isRequired,
  selectedReport: PropTypes.object,
};
Results.defaultProps = {
  initialState: {},
};

export default branch(
  {
    clients: clientsSelector(),
    events: eventsSelector(),
    invests: investsSelector(),
    reports: reportsSelector(),
  },
  Results,
);
