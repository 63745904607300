const STRUCT = {
  ID: 'id',
  CLIENT_INFO: 'clientInfo',
  MEETING: 'meeting',
  GRADES: 'grades',
  COMMENT: 'comment',
  QUESTION_FORM_RESULT: 'question_form_result',
};

export default STRUCT;
