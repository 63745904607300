const process = n => `${n < 10 ? '0' : ''}${n}`;

export const getTime = str => {
  const date = new Date(str);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${process(hours)}:${process(minutes)}`;
};
/**
 * Return range time
 * @param {string} dataStart
 * @param {string} dataEnd
 * @return {string}
 */
export const getTimeRange = (dataStart, dataEnd) =>
  `${getTime(dataStart)}-${getTime(dataEnd)}`;

/**
 * Return range time
 * @param {string} dataStart
 * @param {string} dataEnd
 * @return {Array}
 */
export const getTimeRangeArr = (dataStart, dataEnd) => [
  getTime(dataStart),
  getTime(dataEnd),
];

export const isDatesEqual = (date1Str, date2Str) => {
  const date1 = new Date(date1Str);
  const date2 = new Date(date2Str);

  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const isDateLater = (date1Str, date2Str) => {
  const date1 = new Date(date1Str);
  const date2 = new Date(date2Str);

  return (
    (date1.getDate() > date2.getDate() &&
      date1.getMonth() === date2.getMonth()) ||
    date1.getMonth() > date2.getMonth()
  );
};

/**
 * Return range time
 * @param {string[]} timeRange1
 * @param {string[]} timeRange2
 * @return {boolean}
 */
export const isTimeIntervalsIntersect = (timeRange1, timeRange2) => {
  const from1 = Number(timeRange1[0].replace(':', ''));
  const from2 = Number(timeRange2[0].replace(':', ''));
  const to1 = Number(timeRange1[1].replace(':', ''));
  const to2 = Number(timeRange2[1].replace(':', ''));

  return !(to1 < from2 || from1 > to2);
};
