import STRUCT from '.';
import ENTITIES from './entities';
import CALCULATED from './calculated';

export const agentsSelector = () => [STRUCT.ENTITIES, ENTITIES.AGENT];
export const argumentsSelector = () => [STRUCT.ENTITIES, ENTITIES.ARGUMENT];
export const clientInfoSelector = () => [STRUCT.ENTITIES, ENTITIES.CLIENT_INFO];
export const clientsSelector = () => [STRUCT.ENTITIES, ENTITIES.CLIENT];
export const currentGameSelector = () => [STRUCT.ENTITIES, ENTITIES.GAME];
export const eventsSelector = () => [STRUCT.ENTITIES, ENTITIES.EVENT];
export const institutionsSelector = () => [
  STRUCT.ENTITIES,
  ENTITIES.INSTITUTION,
];
export const investsSelector = () => [STRUCT.ENTITIES, ENTITIES.INVEST];
export const meSelector = () => [STRUCT.ENTITIES, ENTITIES.ME];
export const meetingsSelector = () => [STRUCT.ENTITIES, ENTITIES.MEETING];
export const myRatingSelector = () => [STRUCT.ENTITIES, ENTITIES.MY_RATING];
export const positionsSelector = () => [STRUCT.ENTITIES, ENTITIES.POSITION];
export const reportsSelector = () => [STRUCT.ENTITIES, ENTITIES.REPORTS];
export const sixIbsCategorySelector = () => [
  STRUCT.ENTITIES,
  ENTITIES.SIX_IBS_CATEGORY,
];
export const sixIbsParamSelector = () => [
  STRUCT.ENTITIES,
  ENTITIES.SIX_IBS_PARAM,
];
export const teamsRatingSelector = () => [
  STRUCT.ENTITIES,
  ENTITIES.TEAM_RATING,
];
export const teamsSelector = () => [STRUCT.ENTITIES, ENTITIES.TEAM];
export const timeslotsSelector = () => [STRUCT.ENTITIES, ENTITIES.TIMESLOTS];

// Calculated
export const currentClientsSelector = () => [
  STRUCT.CALCULATED,
  CALCULATED.CURRENT_CLIENTS,
];
export const currMeetingsSelector = () => [
  STRUCT.CALCULATED,
  CALCULATED.CURRENT_MEETINGS,
];
export const gamesSelector = () => [STRUCT.CALCULATED, CALCULATED.GAMES];
export const agentRatingSelector = () => [
  STRUCT.CALCULATED,
  CALCULATED.AGENT_RATING,
];
export const agentQueuesSelector = () => [
  STRUCT.CALCULATED,
  CALCULATED.AGENT_QUEUE,
];
export const logsSelector = () => [STRUCT.CALCULATED, CALCULATED.LOGS];
