import STRUCT from '../';
import ENT_STRUCT from '../entities';
import USER_STRUCT from '../entities/user';
import { meSelector } from '../selectors';

export function set(store, data) {
  store.select(STRUCT.ENTITIES).set(data);
}

export function update(store, entity, data) {
  if (store.exists([STRUCT.ENTITIES, entity])) {
    if (entity === ENT_STRUCT.AGENT) {
      const user = store.get(meSelector());

      if (
        user &&
        USER_STRUCT.INSTITUTION_ID in user &&
        USER_STRUCT.INSTITUTION_ID in data
      ) {
        if (
          user[USER_STRUCT.INSTITUTION_ID] === data[USER_STRUCT.INSTITUTION_ID]
        ) {
          store.select([STRUCT.ENTITIES, entity, { id: data.id }]).merge(data);
        }
      } else {
        store.select([STRUCT.ENTITIES, entity, { id: data.id }]).merge(data);
      }
    } else if (entity === ENT_STRUCT.ME) {
      const user = store.get(meSelector());

      if (user && user.id === data.id) {
        store.select([STRUCT.ENTITIES, entity]).set(data);
      }
    } else if (
      entity === ENT_STRUCT.MY_RATING ||
      entity === ENT_STRUCT.MY_6IBS_GRADE
    ) {
      const user = store.get(meSelector());

      if (user && user.id === data.id) {
        store.select([STRUCT.ENTITIES, entity]).set(data.rating);
      }
    } else if (Array.isArray(data)) {
      store.select([STRUCT.ENTITIES, entity]).set(data);
    } else if (store.exists([STRUCT.ENTITIES, entity, { id: data.id }])) {
      store.select([STRUCT.ENTITIES, entity, { id: data.id }]).merge(data);
    } else {
      create(store, entity, data);
    }
  }
}

export function create(store, entity, data) {
  store.select([STRUCT.ENTITIES, entity]).push(data);
}

export function remove(store, entity, data) {
  const cursor = store.select([STRUCT.ENTITIES, entity]);

  // todo Move code about meeting into ./meeting
  if (entity === ENT_STRUCT.MEETING) {
    cursor.set(cursor.get().filter(({ id }) => id !== data.id));
  } else {
    cursor.splice([data, 1]);
  }
}
