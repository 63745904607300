import React, { useCallback } from 'react';
import { branch } from 'baobab-react/higher-order';
import Dialog from '../../components/dialog';
import { CONFIRM_RESULTS } from '../../store/struct/app';
import { confirmWindowSelector } from '../../store/struct/app/selectors';
import { setConfirmWindowResult } from '../../store/struct/app/actions';

const ConfirmDialog = ({ confirmWindow, dispatch }) => {
  const confirm = useCallback(() => {
    dispatch(setConfirmWindowResult, CONFIRM_RESULTS.APPROVE);
  }, [dispatch]);
  const cancel = useCallback(() => {
    dispatch(setConfirmWindowResult, CONFIRM_RESULTS.CANCEL);
  }, [dispatch]);

  return (
    confirmWindow && (
      <Dialog
        title={'Подтверждение'}
        submitTitle={'Да'}
        cancelTitle={'Нет'}
        onSubmit={confirm}
        onCancel={cancel}
      >
        Вы подтверждаете действие?
      </Dialog>
    )
  );
};

export default branch(
  {
    confirmWindow: confirmWindowSelector(),
  },
  ConfirmDialog,
);
