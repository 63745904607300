const STRUCT = {
  GAME_ID: 'gameExerciseId',
  ID: 'id',
  DATETIME: 'timestamp',
  USER_ID: 'userId',
  ROLE: 'role',
  DATA: 'data',
};

export default STRUCT;
