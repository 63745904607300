import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Table, { TYPES as TABLE_TYPES } from '../../../../components/table';
import { PARAMS } from '../../../../components/form/select';

import SIXIBS_PARAM_STRUCT from '../../../../store/struct/entities/sixIbsParam';

import styles from '../index.module.scss';

const HEADERS = ['Наблюдаемое поведение (критерии оценки)', 'Значение'];
const OPTIONS = [
  { [PARAMS.ID]: 1, [PARAMS.TITLE]: 'Не соответствует ожиданиям' },
  { [PARAMS.ID]: 2, [PARAMS.TITLE]: 'Частично соответствует ожиданиям' },
  { [PARAMS.ID]: 3, [PARAMS.TITLE]: 'Полностью соответствует ожиданиям' },
  { [PARAMS.ID]: 4, [PARAMS.TITLE]: 'Превзошёл(-ла) ожидания' },
];

const Estimates = ({ onChange, data }) => {
  const tableData = data.map(param => ({
    id: param[SIXIBS_PARAM_STRUCT.ID],
    name: param[SIXIBS_PARAM_STRUCT.TITLE],
  }));

  const [state, setState] = useState(
    tableData.reduce(
      (result, param) => ({
        ...result,
        [param.id]: 0,
      }),
      {},
    ),
  );

  const tableConfig = useMemo(
    () => [
      {
        type: TABLE_TYPES.TEXT,
        getValue: data => data.name,
      },
      {
        type: TABLE_TYPES.SELECT,
        onChange: (data, value) => setState({ ...state, [data.id]: value }),
        placeholder: 'Введите ответ',
        options: OPTIONS,
        min: true,
      },
    ],
    [state],
  );

  useEffect(() => {
    onChange(state);
  }, [state, onChange]);

  return (
    <Table
      className={styles.estimatesTable}
      headers={HEADERS}
      config={tableConfig}
      data={tableData}
    />
  );
};

Estimates.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default Estimates;
