const STRUCT = {
  ID: 'result_id',
  APPOINTED_EVENTS: 'appointed_events',
  CLIENT_ID: 'client_id',
  MEETING_ID: 'meeting_id',
  GRADES: 'grades',
  COMMENT: 'comment',
  QUESTION_FORM_RESULT: 'question_form_result',
  REPRESENTATIVE_ID: 'representative_id',
  GOAL: 'goal',
  MEETING_RESULTS_ID: 'result_id',
  PLANNED_START: 'plannedStart',
  CREATE_REQUEST: 'create_request',
  APPROVE_REQUEST: 'approve_request',
};

export default STRUCT;
