const STRUCT = {
  ADD_PATIENTS_COEF: 'add_patients_coef',
  EMAIL: 'email',
  GAME_ID: 'game_id',
  ADD_PATIENTS: 'has_add_patients',
  APPROVE_REQUEST: 'has_approve_request',
  CHECK_FORM: 'has_check_form',
  CONTRACT: 'has_contract',
  CREATE_REQUEST: 'has_create_request',
  PRESCRIPT: 'has_prescript',
  PRESCRIPT_DISCOUNT: 'has_prescript_discount',
  PRESCRIPT_RETAIL: 'has_prescript_retail',
  ID: 'id',
  INSTITUTION_ID: 'institution_id',
  NAME: 'name',
  POSITION_ID: 'position_id',
  PRESCRIPT_COEF: 'prescript_coef',
  PRESCRIPT_DISCOUNT_COEF: 'prescript_discount_coef',
  PRESCRIPT_RETAIL_COEF: 'prescript_retail_coef',
  RULES: 'rules',
  CLIENT_INFO: 'info',
  PIN: 'pin', // pins are only sent to the admins
  HAS_ACCESS_TO_REPORTS: 'has_report_tab',
  QUESTION_FORM: 'question_form',
};

export const QUESTION_FORM_STRUCT = {
  QUESTION: 'question',
  ANSWERS: 'answers',
};

export default STRUCT;
