import React, { useMemo } from 'react';
import { branch } from 'baobab-react/higher-order';

import { agentRatingSelector } from '../../store/struct/selectors';
import { toDecimalPlaces } from '../../utils';
import AGENT_RATING_STRUCT from '../../store/struct/calculated/agentRating';

import Table, { TYPES as TABLE_TYPES } from '../../components/table';

import styles from './index.module.scss';

const HEADERS = ['Порядок', 'Представитель', 'Оценки', 'Сумма', 'Цель'];

const AgentRating = props => {
  const { agentRating } = props;

  const tableConfig = useMemo(() => {
    return [
      {
        type: TABLE_TYPES.TEXT,
        getValue: (ratingData, i) => i + 1,
      },
      {
        type: TABLE_TYPES.TEXT,
        getValue: ratingData => ratingData[AGENT_RATING_STRUCT.NAME],
      },
      {
        type: TABLE_TYPES.TEXT,
        getValue: ratingData =>
          ratingData[AGENT_RATING_STRUCT.GRADES]
            ? ratingData[AGENT_RATING_STRUCT.GRADES]
                .map(grade => toDecimalPlaces(grade, 2))
                .join('/')
            : '-',
      },
      {
        type: TABLE_TYPES.TEXT,
        getValue: ratingData =>
          ratingData[AGENT_RATING_STRUCT.GRADE_SUM]
            ? toDecimalPlaces(ratingData[AGENT_RATING_STRUCT.GRADE_SUM], 2)
            : 0,
      },
      {
        type: TABLE_TYPES.TEXT,
        getValue: ratingData => {
          if (!ratingData[AGENT_RATING_STRUCT.GOAL]) {
            return '-';
          }

          return (
            (ratingData[AGENT_RATING_STRUCT.GRADE_SUM]
              ? toDecimalPlaces(
                  (ratingData[AGENT_RATING_STRUCT.GRADE_SUM] * 100) /
                    ratingData[AGENT_RATING_STRUCT.GOAL],
                  2,
                )
              : '0') + '%'
          );
        },
      },
    ];
  }, []);

  return (
    <Table
      keyName={AGENT_RATING_STRUCT.AGENT_ID}
      className={styles.tableWithHeader}
      headers={HEADERS}
      config={tableConfig}
      data={agentRating}
    />
  );
};

export default branch(
  {
    agentRating: agentRatingSelector(),
  },
  AgentRating,
);
