import {
  pinSelector,
  roleSelector,
  authStatusSelector,
  connectionStatusSelector,
  pinSendingStatusSelector,
  userIdSelector,
  confirmWindowSelector,
  confirmResultSelector,
} from './selectors';
import { connection } from '../..';
import { resetStore } from '../actions';
import { setCookie } from '../../../utils/cookie';
import { AUTHORIZED, CONFIRM_RESULTS } from '.';

export function setConnectionStatus(store, value) {
  store.set(connectionStatusSelector(), value);
}

export function setAuthStatus(store, value) {
  if (!value) {
    resetStore(store);
  }
  setCookie(AUTHORIZED, value, { 'max-age': 86400 });
  store.set(authStatusSelector(), value);
  store.commit();
}

export function sendPin(store, pin) {
  store.set(pinSendingStatusSelector(), true);
  setAuthStatus(store, true);
  connection.setPin(pin);
  //change([STRUCT.PIN], pin);
}

export function setCurrentPin(store, pin) {
  store.set(pinSelector(), pin);
  store.set(pinSendingStatusSelector(), false);
}

export function setCurrentRole(store, role) {
  store.set(roleSelector(), role);
}
export function setCurrentUserId(store, userId) {
  store.set(userIdSelector(), userId);
}

export function logout(store) {
  connection.logout();
  store.set(pinSelector(), '');
  store.set(roleSelector(), 0);
}

export async function showConfirmWindow(store) {
  return await new Promise(resolve => {
    store.set(confirmWindowSelector(), true);
    store.commit();
    const confirmResultCursor = store.select(confirmResultSelector());

    confirmResultCursor.once('update', () => {
      const result = confirmResultCursor.get();
      if (result === CONFIRM_RESULTS.IDLE) {
        hideConfirmWindow(store);

        return;
      }

      resolve(result === CONFIRM_RESULTS.APPROVE);
      hideConfirmWindow(store);
      confirmResultCursor.set(CONFIRM_RESULTS.IDLE);
      //confirmResultCursor.release();
    });
  });
}

export async function execAfterUserConfirmation(
  store,
  actionAfterConfirm,
  actionAfterCancel,
) {
  const result = await showConfirmWindow(store);

  if (result && actionAfterConfirm) {
    return actionAfterConfirm();
  } else if (!result && actionAfterCancel) {
    return actionAfterCancel();
  }
}

export async function setConfirmWindowResult(store, result) {
  store.set(confirmResultSelector(), result);
}

export function hideConfirmWindow(store) {
  store.set(confirmWindowSelector(), false);
  store.commit();
}
