import React, { useMemo, useState, useCallback } from 'react';
import { branch } from 'baobab-react/higher-order';

import Timer from '../../../components/timer';
import Dialog from '../../../components/dialog';
import Grades from './grades';
import QuestionForm from './question-form';
import Comment from './comment';

import { MEETING_TIME } from '../../../store/struct/entities/meeting';
import {
  meSelector,
  sixIbsParamSelector,
} from '../../../store/struct/selectors';

import styles from './index.module.scss';
import CLIENT_STRUCT from '../../../store/struct/entities/client';

const BTNS = {
  BACK: 'back',
  NEXT: 'next',
  SAVE: 'save',
};

const STEPS = {
  GRADES: 0,
  QUESTION_FORM: 1,
  COMMENT: 2,
};

const Step = ({ step, current, children }) => (
  <div className={step === current ? '' : styles.hidden}>{children}</div>
);

const MeetingDialog = ({ sixIbsParam, questionFormString, onEnd }) => {
  const [step, setStep] = useState(0);
  const [gradesState, setGradesState] = useState({});
  const [questionFormResult, setQuestionForm] = useState([]);
  const [comment, setComment] = useState('');
  const stepOrders = useMemo(() => {
    const arr = [STEPS.COMMENT];

    if (questionFormString) {
      arr.unshift(STEPS.QUESTION_FORM);
    }
    if (sixIbsParam) {
      arr.unshift(STEPS.GRADES);
    }

    return arr;
  }, [sixIbsParam, questionFormString]);

  const handleClick = useCallback(
    id => {
      if (id === BTNS.BACK) {
        setStep(step - 1);
      }
      if (id === BTNS.NEXT) {
        setStep(step + 1);
      }
      if (id === BTNS.SAVE) {
        onEnd({ gradesState, questionFormResult, comment });
      }
    },
    [step, onEnd, questionFormResult, gradesState, comment],
  );
  const buttonsConfig = useMemo(() => {
    const btns = [];

    if (step > 0) {
      btns.push({
        id: BTNS.BACK,
        title: 'Назад',
      });
    }
    if (step < stepOrders.length - 1) {
      btns.push({
        id: BTNS.NEXT,
        title: 'Далее',
        disabled:
          (stepOrders[step] === STEPS.QUESTION_FORM &&
            questionFormResult.some(answer => answer < 0)) ||
          (stepOrders[step] === STEPS.GRADES &&
            Object.values(gradesState).some(grade => grade === 0)),
      });
    }
    if (step === stepOrders.length - 1) {
      btns.push({
        id: BTNS.SAVE,
        title: 'Сохранить',
      });
    }
    return btns;
  }, [step, stepOrders, questionFormResult, gradesState]);

  return (
    <Dialog
      title={
        <>
          <div>Встреча</div>
          <Timer time={MEETING_TIME} />
        </>
      }
      buttons={buttonsConfig}
      onClick={handleClick}
    >
      {sixIbsParam && (
        <Step step={STEPS.GRADES} current={stepOrders[step]}>
          <Grades data={sixIbsParam} onChange={setGradesState} />
        </Step>
      )}
      {questionFormString && (
        <Step step={STEPS.QUESTION_FORM} current={stepOrders[step]}>
          <QuestionForm data={questionFormString} onChange={setQuestionForm} />
        </Step>
      )}
      <Step step={STEPS.COMMENT} current={stepOrders[step]}>
        <Comment onChange={setComment} />
      </Step>
    </Dialog>
  );
};

export default branch(
  {
    currentUser: meSelector(),
    sixIbsParam: sixIbsParamSelector(),
    questionFormString: [...meSelector(), 0, CLIENT_STRUCT.QUESTION_FORM],
  },
  MeetingDialog,
);
