import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Table, { TYPES as TABLE_TYPES } from '../../../../components/table';
import { PARAMS } from '../../../../components/form/select';

import { QUESTION_FORM_STRUCT } from '../../../../store/struct/entities/client';

import styles from '../index.module.scss';

const ID = 'id';
const QUESTION = 'question';
const ANSWERS = 'answers';

const QuestionForm = ({ onChange, data }) => {
  const questionForm = useMemo(() => {
    try {
      return JSON.parse(data).map((field, fieldIndex) => ({
        [ID]: fieldIndex,
        [QUESTION]: field[QUESTION_FORM_STRUCT.QUESTION],
        [ANSWERS]: [
          { [PARAMS.ID]: -1, [PARAMS.TITLE]: 'Выберите ответ' },
          ...field[QUESTION_FORM_STRUCT.ANSWERS].map((answer, index) => ({
            [PARAMS.TITLE]: answer,
            [PARAMS.ID]: index,
          })),
        ],
      }));
    } catch (e) {
      console.warn(e);
      return [];
    }
  }, [data]);
  const [state, setState] = useState(
    questionForm.reduce((result, field, index) => {
      result[index] = -1;

      return result;
    }, []),
  );

  const tableConfig = useMemo(
    () => [
      {
        type: TABLE_TYPES.TEXT,
        getValue: data => data[QUESTION],
      },
      {
        type: TABLE_TYPES.SELECT,
        onChange: (data, value) => {
          const newState = state.slice();

          newState[data[ID]] = value;
          setState(newState);
        },
        getValue: () => '',
        getOptions: data => data[ANSWERS],
      },
    ],
    [state],
  );

  useEffect(() => {
    onChange(state);
  }, [state, onChange]);

  return (
    <Table
      className={styles.estimatesTable}
      headers={['Вопрос', 'Ответ']}
      config={tableConfig}
      data={questionForm}
    />
  );
};

QuestionForm.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default QuestionForm;
